/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import Router from 'next/router';
import PropTypes from 'prop-types';

import { UserContext } from 'contexts/userContext';

const HocWrapper = ({ children, hoc, pageLoading }) => {
  const { isLoggedIn } = useContext(UserContext);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!pageLoading && hoc) {
      if (isLoggedIn) {
        setLoading(false);
      } else {
        Router.push('/login');
      }
    }
  }, [pageLoading, hoc]);

  if (!hoc) {
    return children;
  }

  return (
    <>
      {!loading && children}
    </>
  );
};

HocWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  hoc: PropTypes.bool,
  pageLoading: PropTypes.bool,
};

export default HocWrapper;
