class ServerTimeSync {
  constructor() {
    this.offsetMs = null;
  }

  calculateOffset(serverTime) {
    if (serverTime) {
      this.offsetMs = new Date(serverTime) - new Date();
    }
  }
}

export default new ServerTimeSync();
