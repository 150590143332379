import { SET_ERROR_STATUS, SET_LOADING_STATUS, SET_SOCKET_STATUS } from 'contexts/constants/statusConstants';

export const statusReducer = (state, action) => {
  switch (action.type) {
  case SET_ERROR_STATUS:
    return {
      ...state,
      error: action.payload,
    };
  case SET_LOADING_STATUS:
    return {
      ...state,
      loading: action.payload,
    };
  case SET_SOCKET_STATUS:
    return {
      ...state,
      socketConnected: action.payload,
    };
  default:
    return state;
  }
};
