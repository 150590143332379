import React from 'react';
import PropTypes from 'prop-types';

import Head from './Head';

const EmptyLayout = ({ children, headTitle = 'iyiMakina' }) => (
  <div>
    <Head title={headTitle} />
    <body>
      {children}
    </body>
  </div>
);

EmptyLayout.propTypes = {
  headTitle: PropTypes.string,
  children: PropTypes.element,
};

export default EmptyLayout;
