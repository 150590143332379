import React, { useEffect } from 'react';

import Image from 'next/image';

const Loading = () => {
  useEffect(() => {
    const body = document.getElementsByClassName('body');
    body[0]?.classList?.add('stopScrolling');

    return () => body[0]?.classList?.remove('stopScrolling');
  }, []);
  return (
    <div className="loading-container">
      <Image src="/image/loading.gif" width="150" height="150" />
    </div>
  );
};

export default Loading;
