import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CookieModal from 'components/modals/CookieModal';

import { withTranslation } from 'language/i18n';

const CookieBar = ({ t }) => {
  const [visible, setVisible] = useState(false);
  const [visibleCookie, setVisibleCookie] = useState(false);

  useEffect(() => {
    checkCookieAccepted();
  }, []);

  const checkCookieAccepted = () => {
    const data = localStorage.getItem('cookieAccepted');
    if (_.isNil(data)) {
      setVisible(true);
    }
  };

  if (visible) {
    return (
      <div className="im-notification im-notification--fixed-bottom im-notification--active">
        <div className="im-notification__inner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-15 col-sm mb-3 mb-sm-0">
                <p>
                  Siteyi ziyaretiniz sırasında kişisel verileriniz teknik gerekliliklerin karşılanması amacıyla çerezler aracılığıyla işlenmektedir.
                  Çerez kullanımını kabul edebilir, ayarlarınızdan çerezleri silebilir veya engelleyebilirsiniz. Daha fazla bilgi için iyiMakina.com Sitesi Çerez Aydınlatma Metni'ni ve Kullanım Koşulları'nı okuyabilirsiniz.
                  {' '}
                  <Link href="/">
                    <a>{process.env.domain}</a>
                  </Link>
                  {' '}
                  Sitesi
                  {' '}
                  <a onClick={() => setVisibleCookie(true)}>Çerez Aydınlatma Metni</a>
                  'ni
                  okuyabilirsiniz.
                </p>
              </div>
              <div className="col-15 col-sm-auto">
                <button
                  className="im-button im-button--white"
                  type="button"
                  onClick={() => {
                    localStorage.setItem('cookieAccepted', 'yes');
                    setVisible(false);
                  }}
                >
                  {t('acceptCookies')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="im-notification__close">
          <button
            type="button"
            className="im-button im-button--transparent im-button--auto-height im-button--no-gutter js-notification-close"
            onClick={() => setVisible(false)}
          >
            <i className="far fa-times" />
          </button>
        </div>
        <CookieModal
          visible={visibleCookie}
          setVisible={setVisibleCookie}
          onClickAccept={() => {
            localStorage.setItem('cookieAccepted', 'yes');
            setVisible(false);
          }}
        />
      </div>
    );
  }

  return null;
};

CookieBar.propTypes = {
  t: PropTypes.func,
};

export default withTranslation('common')(CookieBar);
