import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import { statusReducer } from 'contexts/reducers/statusReducer';
import { SET_ERROR_STATUS } from 'contexts/constants/statusConstants';

export const StatusContext = createContext();

const StatusContextProvider = (props) => {
  const [statusData, dispatch] = useReducer(statusReducer, {
    error: {},
    loading: true,
    socketConnected: false,
  });

  const setErrorState = (content) => {
    dispatch({
      type: SET_ERROR_STATUS,
      payload: content,
    });
  };

  return (
    <StatusContext.Provider value={{ statusData, setErrorState, dispatch }}>
      {props.children}
    </StatusContext.Provider>
  );
};

StatusContextProvider.propTypes = {
  children: PropTypes.object,
};

export default StatusContextProvider;
