/* eslint-disable react-hooks/exhaustive-deps */
import React, { createRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import socketInstance from 'utils/socket.io';
import request from 'utils/request';
import { UserContext } from 'contexts/userContext';
import { StatusContext } from 'contexts/statusContext';
import { PaymentContext } from 'contexts/paymentContext';
import {
  isTokenExist, getUserData, removeToken,
} from 'models/user';
import { getUserDeposit } from 'models/payment';
import ErrorModal from 'components/modals/ErrorModal';
import CookieBar from 'components/CookieBar';
import Loading from 'components/Loading';
import HocWrapper from 'components/HocWrapper';

import { SET_ALL } from 'contexts/constants/userConstants';
import { SET_LOADING_STATUS, SET_SOCKET_STATUS } from 'contexts/constants/statusConstants';

export const fullScreenModalAreaRef = createRef();

const WrapperComponent = ({ children, ...props }) => {
  const { dispatch: userDispatch } = useContext(UserContext);
  const { statusData, setErrorState, dispatch: statusDispatch } = useContext(StatusContext);
  const { refreshDepositAmount, refreshPlatformUsageFeePercentage } = useContext(PaymentContext);

  const checkLoggedIn = async () => {
    try {
      if (isTokenExist()) {
        const { user } = await getUserData();
        const { userBuyingLimit } = await getUserDeposit();
        userDispatch({ type: SET_ALL, payload: { ...user, userBuyingLimit } });
      } else {
        // Just for server time sync
        await request('api/v1', 'get');
      }
    } catch (error) {
      console.log('checkLoggedInErr', error);
      removeToken();
    } finally {
      socketInstance.connect(
        () => statusDispatch({
          type: SET_SOCKET_STATUS,
          payload: true,
        }),
        () => statusDispatch({
          type: SET_SOCKET_STATUS,
          payload: false,
        }),
      );
      statusDispatch({
        type: SET_LOADING_STATUS,
        payload: false,
      });
    }
  };

  useEffect(() => {
    checkLoggedIn();
    refreshDepositAmount();
    refreshPlatformUsageFeePercentage();
  }, []);

  return (
    <>
      {statusData.loading && <Loading />}
      <HocWrapper {...{ pageLoading: statusData.loading, ...props }}>
        {children}
      </HocWrapper>
      <ErrorModal
        visible={!_.isEmpty(statusData.error)}
        setVisible={() => setErrorState({})}
        {...statusData.error}
      />
      <CookieBar />
      <div ref={fullScreenModalAreaRef} />
    </>
  );
};

WrapperComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

export default WrapperComponent;
