import request from 'utils/request';
import _ from 'lodash';

export const payDeposit = async ({
  cardNumber,
  fullName,
  cardMonth,
  cardYear,
  cardSecurtyCode,
  amount,
}) => {
  const data = await request('api/v1/payment/securePayment', 'post', {
    body: JSON.stringify({
      number: cardNumber,
      fullName,
      month: cardMonth,
      year: cardYear,
      cvv: cardSecurtyCode,
      amount,
    }),
  });
  return data;
};

export const getUserDeposit = async () => {
  const data = await request('api/v1/user/buyingLimit');
  return data;
};

export const getCurrency = async (money) => {
  const { EUR } = await request('api/v1/currency');
  const approxValue = money * EUR;
  const approxValueToString = approxValue.toString();
  const dotIndex = _.indexOf(approxValueToString, '.');
  return dotIndex !== -1 ? approxValueToString.substring(0, dotIndex) : approxValueToString;
};

export const getDepositAmount = async () => {
  const data = await request('api/v1/depositAmount');
  return data;
};

export const getBankCommissionRate = async () => {
  const data = await request('api/v1/bankCommission');
  return data;
};

export const getPlatformUsageFeePercentage = async () => {
  const data = await request('api/v1/currentPlatformFeePercentage');
  return data;
};

export const sendPaymentDetailMail = async () => {
  const data = await request('api/v1/payment/paymentInfoMail');
  return data;
};

export const refundRequest = async () => {
  const data = await request('api/v1/payment/refundRequest');
  return data;
};

export const refundRequestById = async (id) => {
  const data = await request(`api/v1/payment/refundRequest/${id}`, 'post');
  return data;
};
