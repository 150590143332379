import {
  SET_ALL, SET_CLEAR,
} from 'contexts/constants/userConstants';

export const userReducer = (state, action) => {
  switch (action.type) {
  case SET_ALL:
    return {
      ...state,
      ...action.payload,
    };
  case SET_CLEAR:
    return {
      isBuyer: false,
      isSeller: false,
    };
  default:
    return state;
  }
};
