import React, { createContext, useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';

import { getDepositAmount, getPlatformUsageFeePercentage } from 'models/payment';
import { paymentConsts } from 'constants/payment';
import { paymentReducer } from 'contexts/reducers/paymentReducer';
import { SET_DEPOSIT_AMOUNT, SET_PLATFORM_USAGE_FEE_PERCENTAGE } from 'contexts/constants/paymentConstants';

export const PaymentContext = createContext();

const PaymentContextProvider = (props) => {
  const [paymentData, dispatch] = useReducer(paymentReducer, {
    depositAmount: 0,
    platformUsageFeePercentage: 0,
  });

  const refreshDepositAmount = async () => {
    try {
      const { depositAmount: newDepositAmount } = await getDepositAmount();
      dispatch({
        type: SET_DEPOSIT_AMOUNT,
        payload: newDepositAmount,
      });
    } catch (error) {
      console.log('refreshDepositAmountErr', error);
    }
  };

  const refreshPlatformUsageFeePercentage = async () => {
    try {
      const { currentPlatformFeePercentage } = await getPlatformUsageFeePercentage();
      dispatch({
        type: SET_PLATFORM_USAGE_FEE_PERCENTAGE,
        payload: currentPlatformFeePercentage,
      });
    } catch (error) {
      console.log('refreshPlatformUsageFeePercentage', error);
    }
  };

  const calculatePlatformUsageFee = useCallback((machinePrice) => {
    let fee = (machinePrice * paymentData.platformUsageFeePercentage) / 100;
    let usedMinimumAmount = false;

    if (fee < paymentConsts.PLATFORM_USAGE_FEE_MINIMUM_EUR) {
      fee = paymentConsts.PLATFORM_USAGE_FEE_MINIMUM_EUR;
      usedMinimumAmount = true;
    }

    return {
      platformUsageFee: fee,
      usedMinimumPlatformUsageFeeAmount: usedMinimumAmount,
    };
  }, [paymentData.platformUsageFeePercentage]);

  return (
    <PaymentContext.Provider value={{
      paymentData,
      dispatch,
      refreshDepositAmount,
      refreshPlatformUsageFeePercentage,
      calculatePlatformUsageFee,
    }}
    >
      {props.children}
    </PaymentContext.Provider>
  );
};

PaymentContextProvider.propTypes = {
  children: PropTypes.object,
};

export default PaymentContextProvider;
