/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';

import Modal from './Modal';

const ErrorModal = ({
  visible, setVisible, type, title, text, buttonText, onClickButton = () => {}, subButtonText, onClickSubButton = () => {},
}) => {
  if (!visible) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
    >
      <div className="modal-content">
        <div className="modal-body">
          <div className="row">
            <div className="col">
              <div className="row mb-4">
                <div className="col text-center">
                  {
                    type === 'success'
                      ? <i className="fas fa-check-circle text-green2 fs-90" />
                      : <i className="fas fa-exclamation-circle text-red1 fs-90" />
                  }
                </div>
              </div>
              <div className="row mb-4">
                <div className="col text-center">
                  <div className="fs-22 fw-700 text-black1">{title}</div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col text-center">
                  <p>
                    {text}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="row mb-3">
                <div className="col">
                  <a
                    className="im-button im-button--primary im-button--fluid im-button--center fw-900"
                    onClick={() => onClickButton()}
                  >
                    {buttonText}
                  </a>
                </div>
              </div>
              {
                subButtonText
                    && (
                      <div className="row">
                        <div className="col">
                          <a
                            className="im-button im-button--transparent im-button--fluid im-button--center fw-900"
                            onClick={() => onClickSubButton()}
                          >
                            {subButtonText}
                          </a>
                        </div>
                      </div>
                    )
              }
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ErrorModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  onClickButton: PropTypes.func,
  subButtonText: PropTypes.string,
  onClickSubButton: PropTypes.func,
};

export default ErrorModal;
