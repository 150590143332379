import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import modalStates from 'constants/contractContents';
import Modal from './Modal';

const CookieModal = ({
  visible, setVisible, onClickAccept,
}) => {
  const modalContentRef = useRef();
  useEffect(() => {
    if (visible) {
      modalContentRef.current.innerHTML = modalStates.cookie;
    }
  }, [visible]);

  if (!visible) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
    >
      <div className="modal-content modal-content-vh">
        <div className="modal-header">
          <div className="modal-title">
            <div className="fs-44 fw-900 text-dark">Çerez Aydınlatma Politikası</div>
          </div>
        </div>
        <div className="modal-body" ref={modalContentRef} />
        <div className="modal-footer modal-footer-sticky">
          <div className="row w-100">
            <div className="col-15 mb-3 mb-sm-0 col-sm">
              <a
                className="im-button im-button--center im-button--fluid im-button--outline-yellow2"
                onClick={() => setVisible(false)}
              >
                Kabul Etmiyorum
              </a>
            </div>
            <div className="col-15 col-sm">
              <a
                className="im-button im-button--center im-button--fluid im-button--primary"
                onClick={onClickAccept}
              >
                Kabul Ediyorum
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

CookieModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  onClickAccept: PropTypes.func,
};

export default CookieModal;
