import React, { createContext, useReducer } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { removeToken } from 'models/user';
import { userReducer } from 'contexts/reducers/userReducer';
import { SET_CLEAR } from 'contexts/constants/userConstants';

export const UserContext = createContext();

const UserContextProvider = (props) => {
  const [userData, dispatch] = useReducer(userReducer, { isBuyer: false, isSeller: false });

  const router = useRouter();

  const logout = () => {
    router.push('/');
    removeToken();
    dispatch({ type: SET_CLEAR, payload: {} });
  };

  const isLoggedIn = _.result(userData, 'id') !== undefined && !_.isEmpty(userData?.id);

  return (
    <UserContext.Provider value={{
      userData, dispatch, isLoggedIn, logout,
    }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.object,
};

export default UserContextProvider;
