import React from 'react';
import App from 'next/app';
import { appWithTranslation } from 'language/i18n';
import PropTypes from 'prop-types';

import GeneralProvider from 'contexts/GeneralProvider';
import WrapperComponent from 'components/WrapperComponent';
import EmptyLayout from 'components/partials/EmptyLayout';

import '../public/styles/main.scss';
import 'moment/locale/tr';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
}

const app = ({ Component, pageProps }) => {
  const Layout = Component.Layout || EmptyLayout;

  return (
    <GeneralProvider>
      <WrapperComponent {...pageProps}>
        <Layout {...pageProps}>
          <Component {...pageProps} />
        </Layout>
      </WrapperComponent>
    </GeneralProvider>
  );
};

app.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object,
};

app.getInitialProps = async (appContext) => ({ ...await App.getInitialProps(appContext) });

export default appWithTranslation(app);
