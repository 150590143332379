import React from 'react';
import PropTypes from 'prop-types';
import UserContextProvider from './userContext';
import StatusContextProvider from './statusContext';
import PaymentContextProvider from './paymentContext';

const GeneralProvider = (props) => (
  <StatusContextProvider>
    <UserContextProvider>
      <PaymentContextProvider>
        {props.children}
      </PaymentContextProvider>
    </UserContextProvider>
  </StatusContextProvider>
);

GeneralProvider.propTypes = {
  children: PropTypes.element,
};

export default GeneralProvider;
