import { SET_DEPOSIT_AMOUNT, SET_PLATFORM_USAGE_FEE_PERCENTAGE } from 'contexts/constants/paymentConstants';

export const paymentReducer = (state, action) => {
  switch (action.type) {
  case SET_DEPOSIT_AMOUNT:
    return {
      ...state,
      depositAmount: action.payload,
    };
  case SET_PLATFORM_USAGE_FEE_PERCENTAGE:
    return {
      ...state,
      platformUsageFeePercentage: action.payload,
    };
  default:
    return state;
  }
};
