/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { fullScreenModalAreaRef } from 'components/WrapperComponent';

const Modal = ({
  visible, setVisible, children, className = '', isVideoModal,
}) => {
  const mainElement = useRef(document.createElement('div'));

  useEffect(() => {
    document.addEventListener('keydown', handleEsc);
    const body = document.getElementsByClassName('body');
    const fullScreenModalArea = fullScreenModalAreaRef?.current;

    body[0]?.classList?.add('stopScrolling');
    fullScreenModalArea?.appendChild(mainElement.current);

    return () => {
      document.removeEventListener('keydown', handleEsc);
      body[0]?.classList?.remove('stopScrolling');
      fullScreenModalArea?.removeChild(mainElement.current);
    };
  }, []);

  const handleEsc = (event) => {
    if (event.keyCode === 27) {
      setVisible(false);
    }
  };

  const modalContent = () => {
    if (!visible) {
      return null;
    }

    return (
      <div
        className={`modal fade bd-example-modal-lg im-modal show ${className}`}
        style={{ display: isVideoModal ? 'flex' : 'block' }}
      >
        <div className={`${isVideoModal ? 'video-modal-video-container' : 'modal-dialog modal-lg modal-dialog-centered modal-dialog-viewport-height'} animate__animated animate__fadeInDown`}>
          {children}
        </div>
        <div className="modal-close-button" onClick={() => setVisible(false)}>
          <i className="fas fa-times" />
          <div>ESC</div>
        </div>
      </div>
    );
  };

  return createPortal(modalContent(), mainElement.current);
};

Modal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
  isVideoModal: PropTypes.bool,
};

export default Modal;
