import { getToken, isTokenExist, removeToken } from 'models/user';
import Router from 'next/router';
import serverTimeSync from 'config/serverTimeSync';

const checkStatus = async (response) => {
  const isIncludeApplicationJson = response?.headers?.get('content-type')?.includes('application/json');

  if (response.ok && response.status >= 200 && response.status < 300) {
    const responseJson = await response.json();
    const serverTime = response.headers?.get('server-time');
    serverTimeSync.calculateOffset(serverTime);
    return { ...responseJson, status: response.status };
  }

  if (response.status === 401) {
    removeToken();
    return Router.push('/login');
  }

  return Promise.reject(
    isIncludeApplicationJson
      ? await response.json()
      : { message: 'Bir hata oluştu.' },
  );
};

const request = (endpoint, method = 'get', {
  body = JSON.stringify({}), headers = {}, options = {}, host,
} = {}) => {
  const customHeaders = {
    'Content-Type': 'application/json',
    ...headers,
  };

  if (isTokenExist()) {
    customHeaders.Authorization = `Bearer ${getToken()}`;
  }

  const customOptions = {
    method,
    headers: customHeaders,
    ...options,
  };

  if (method !== 'get' && body) {
    customOptions.body = body;
  }

  /*  console.log(JSON.stringify({
    url: `${host || process.env.host}/${endpoint}`,
    options: customOptions,
  })); */

  return fetch(`${host || process.env.host}/${endpoint}`, customOptions)
    .then(checkStatus);
};

export default request;
