import { io } from 'socket.io-client';
import { getToken } from 'models/user';

class SocketIo {
  constructor() {
    this.socket = {};
  }

  connect(onConnect = () => {}, onDisconnect = () => {}) {
    this.socket = io(process.env.marketplace, { transports: ['websocket'], auth: { token: getToken() } });

    this.socket.on('connect', () => {
      console.log('Socket connected.');
      onConnect();
    });

    this.socket.on('disconnect', (reason, details) => {
      console.log('Socket disconnected. Reason:', reason, 'Details:', details);
      onDisconnect();
    });
  }
}

export default new SocketIo();
